<template>
  <div class="exam-apply">
    <div class="exam-form">
      <div class="exam-from-title">
        <span style="font-size: 25px; margin-top: 20px; font-weight: bold"
          >考场申请</span
        >
      </div>
      <div class="exam-apply-main">
        <el-form
          :model="applyForm"
          :rules="rules"
          ref="applyForm"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-form-item label="申请单位" prop="school">
            <el-input
              v-model="applyForm.school"
              style="width: 290px"
            ></el-input>
          </el-form-item>
          <el-form-item label="学校联系人" prop="name">
            <el-input v-model="applyForm.name" style="width: 290px"></el-input>
          </el-form-item>
          <el-form-item label="学校联系人电话" prop="tel">
            <el-input
              v-model="applyForm.tel"
              onkeyup="value=value.replace(/[^\d]/g,'')"
              style="width: 290px"
            ></el-input>
          </el-form-item>

          <!-- <el-form-item label="验证码" prop="code">
            <el-input v-model="applyForm.code" style="width: 180px"></el-input>
            <div class="img-code">
              <img src="" alt="">
            </div>
          </el-form-item> -->

          <el-form-item label="考场名称" prop="examName">
            <el-input
              v-model="applyForm.examName"
              style="width: 290px"
            ></el-input>
          </el-form-item>

          <!-- <el-form-item label="考场使用时间" prop="dateTime">
            <el-date-picker
              v-model="applyForm.dateTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 290px"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item> -->

          <el-form-item label="考级内容" prop="examLevel">
            <el-select
              v-model="applyForm.examLevel"
              placeholder="请选择"
              style="width: 290px"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div class="dialog-footer">
            <el-button
              style="margin-left: 30px"
              type="primary"
              @click="confirmForm('applyForm')"
              >确 定</el-button
            >
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { addApply } from "@/utils/apis";
export default {
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //如果没有后面的-8.64e7就是不可以选择今天的
        },
        shortcuts: [
          {
            text: "此刻",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      applyForm: {
        school: "", //申请学校
        name: "", //学校联系人
        tel: "", //联系电话
        examName: "", //考场名称
        // dateTime: "",
        examLevel: "",
      },
      rules: {
        school: [
          { required: true, message: "请输入学校名称", trigger: "blur" },
          {
            min: 3,
            max: 50,
            message: "长度在 3 到 50 个字符",
            trigger: "blur",
          },
        ],
        name: [
          { required: true, message: "请输入联系人", trigger: "blur" },
          {
            min: 2,
            max: 10,
            message: "长度在 3 到 10 个字符",
            trigger: "blur",
          },
        ],
        tel: [
          {
            required: true,
            message: "请输入联系人电话",
            trigger: "blur",
          },
          {
            min: 11,
            max: 11,
            message: "长度在 11 个字符",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
        examName: [
          { required: true, message: "请输入考场名称", trigger: "blur" },
          {
            min: 3,
            max: 50,
            message: "长度在 3 到 50 个字符",
            trigger: "blur",
          },
        ],
        // dateTime: [
        //   {
        //     required: true,
        //     message: "请选择考场使用时间",
        //     trigger: "change",
        //   },
        // ],
        examLevel: [
          {
            required: true,
            message: "请选择考级内容",
            trigger: "change",
          },
        ],
      },
      options: [
        {
          value: 1,
          label: "3级",
        },
        {
          value: 2,
          label: "4级",
        },
      ],
    };
  },
  mounted() {
    console.log();
  },
  methods: {
    confirmForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            examination_room_name: this.applyForm.examName,
            apply_school: this.applyForm.school,
            // examination_start_time: JSON.stringify(
            //   new Date(this.applyForm.dateTime[0]).getTime()
            // ).substring(0, 10),
            // examination_end_time: JSON.stringify(
            //   new Date(this.applyForm.dateTime[1]).getTime()
            // ).substring(0, 10),
            school_contact_user: this.applyForm.name,
            school_contact_mobile: this.applyForm.tel,
            examination_category_id: this.applyForm.examLevel,
            check_center_id: this.$route.query.id,
          };
          addApply(params)
            .then((res) => {
              if (res.code === 200) {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
              }
            })
            .catch((err) => {
              console.log("err", err);
            });
          this.$refs.applyForm.resetFields();
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.exam-apply {
  display: flex;
  padding: 110px 80px;
  justify-content: center;
  box-sizing: border-box;
  .exam-form {
    width: 900px;
    border: 1px solid #ccc;
    border-radius: 5px;
    .exam-from-title {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    .exam-apply-main {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 60px;
      .dialog-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 60px 0;
      }
    }
  }
}
</style>